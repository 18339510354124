.row-details-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-size: 200% 200%;
    background: linear-gradient(to bottom, #e3f2fd, #bbdefb);
    animation: gradient 60s ease infinite;
    padding: 20px;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.row-details {
    max-width: 600px;
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.row-details h2 {
    margin-bottom: 20px;
    text-align: center;
    color: #007bff;
    font-size: 24px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
    font-size: 16px;
}

.form-group span {
    display: block;
    padding: 8px;
    background-color: #f5f5f5;
    border: 2px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
}

.form-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 15px;
}

.form-row .form-group {
    flex: 1;
}

.tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.tag {
    background-color: #e0e0e0;
    color: #333;
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 14px;
}

.added-tag:nth-child(odd) {
    background-color: #007bff;
    color: #fff;
}

.added-tag:nth-child(even) {
    background-color: #28a745;
    color: #fff;
}

.added-tag:nth-child(3n) {
    background-color: #f0932b;
    color: #fff;
}

.added-tag:nth-child(4n) {
    background-color: #eb4d4b;
    color: #fff;
}

.form-group p {
    padding: 8px;
    background-color: #f5f5f5;
    border: 2px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    line-height: 1.5;
}

.edit-button {
    flex: 1;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
}

.edit-button:hover {
    background-color: #052c55;
    transform: translateY(-2px);
}
.btn-secondary {
    background-color: #6c757d;
    color: white;
    font-weight: bold;
}

.btn-secondary:hover {
    background-color: #5a6268;
    transform: translateY(-2px);
}


.action-icon {
    font-size: 24px;
    cursor: pointer;
}

.action-star {
    color: #ffd700;
}
