.table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.table th {
    background-color: #f2f2f2;
    font-weight: bold;
    padding: 12px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: max-content;
}

.table td {
    padding: 12px;
    border-bottom: 1px solid #e0e0e0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: max-content;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-button {
    background-color: white;
    border: none;
    color: #333;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 0 4px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.pagination-button:hover {
    background-color: #ddd;
}

.pagination-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.page-info {
    margin: 0 16px;
    font-size: 14px;
}

.page-size-select {
    margin-left: 16px;
    padding: 6px 12px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
}